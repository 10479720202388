import React from 'react';
import classnames from 'classnames';
import { graphql, PageProps } from 'gatsby';
import get from 'lodash/get';

import { StrapiFooterCtaComponent } from '../components/StrapiComponents/StrapiFooterCta';
import ComponentRenderer from '../components/ComponentRenderer/ComponentRenderer';
import LayoutWrapper, { intlWrapperHOC, v4tov3HOC } from '../components/LayoutWrapper';
import SEO from '../components/SEO/SEO';
import { Scalars, StrapiPageQuery } from '../../graphql.schema';
import { GlobalPageContext } from '../types';
import { v4tov3base } from '../lib/mapV4toV3';

import * as styles from './page.module.scss';

type PageContextType = GlobalPageContext & {
  documentId: Scalars['ID'];
};

const Page: React.FC<PageProps<StrapiPageQuery, PageContextType>> = ({ data, pageContext }) => {
  const { websiteLocale: websiteLocaleV3 } = pageContext;
  const websiteLocale = v4tov3base({ id: websiteLocaleV3?.documentId, ...websiteLocaleV3});
  // hacking our way a bit here since publicationState seems to be an args on collections only in strapi v4
  // @ts-ignore
  const page = get(data, 'strapi.pages[0]', {}) as StrapiPageQuery['strapi']['page'] | undefined;

  if (!page || !websiteLocale) {
    return (
      <LayoutWrapper {...pageContext}>
        <SEO title={data.strapi.page?.title} />
        <h1>Not Found</h1>
      </LayoutWrapper>
    );
  }

  const showFooterCta = page.pageSettings?.showFooterCta ?? true;
  const footerCta = page.pageSettings?.customFooterCta?.documentId
    ? page.pageSettings?.customFooterCta
    : websiteLocale.footerCta;

  return (
    <LayoutWrapper {...pageContext}>
      <SEO
        title={page.seo?.metaTitle ?? page.pageSettings?.title ?? page.title}
        description={page.seo?.metaDescription ?? page.pageSettings?.metaDescription}
        image={page.seo?.metaImage?.url}
        avoidIndexing={page.pageSettings?.avoidIndexing ?? false}
        canonicalURL={page.seo?.canonicalURL}
        keywords={page.seo?.keywords}
        metaSocial={page.seo?.metaSocial}
        structuredData={page.seo?.structuredData}
        alternatePages={pageContext?.availableRoutes}
        currentPage={pageContext}
      />
      <div className={classnames('page-components', styles.pageComponents)}>
        {page.components?.map((component, idx) =>
          component && component.__typename ? (
            <ComponentRenderer
              hasRTL={websiteLocale?.hasRightToLeftLanguage}
              component={component}
              key={`page-component-${page.documentId}-${component.__typename}-${idx}`}
            />
          ) : null,
        )}
      </div>

      {footerCta && showFooterCta && <StrapiFooterCtaComponent {...footerCta} />}
    </LayoutWrapper>
  );
};

export const query = graphql`
query StrapiPage($documentId: ID!, $locale: Strapi_I18NLocaleCode) {
  strapi {
    pages(filters: {documentId: {eq: $documentId}}, locale: $locale) {
      data {
        documentId
        attributes {
          title
          slug
          localizations {
            data {
              attributes {
                locale
              }
            }
          }
          components {
            ...PageComponents
          }
          pageSettings: page_settings {
            ...PageSettings
          }
          seo {
            ...SEOComponent
          }
        }
      }
    }
  }
}
`;

export default intlWrapperHOC(v4tov3HOC(Page));
